import React, { Component } from 'react'
import tania from '../../content/images/1024-rou.png'
import patreon from '../../content/thumbnails/patreon.png'
import kofi from '../../content/thumbnails/kofi.png'

const style = { color: 'white' }
export default class UserInfo extends Component {
  render() {
    return (
      <aside className="note">
        <div className="container note-container">
          <div className="flex-author">
            <div className="flex-avatar">
              <img className="avatar" src={tania} alt="Apex Software" />
            </div>
            <div>
              <p>
                If you like our work, like and subscribe on our <a href="/contact" style={style}>social media platforms</a>. Also leave us
                comments for improvements if you'd like to.
              </p>

              <div className="flex">
                <a
                  href="https://ko-fi.com/sesvtutorial"
                  className="donate-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={kofi} className="coffee-icon" alt="Coffee icon" />
                  Buy us a coffee
                </a>
                <a
                  className="patreon-button"
                  href="https://www.patreon.com/sesvtutorial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={patreon} height="50" width="50" alt="Patreon" /> Become a Patron
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
    )
  }
}
